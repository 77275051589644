import {
  addHours,
  addMinutes,
  format,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
  add,
} from 'date-fns'
import { parse } from 'papaparse'
import groupBy from 'lodash/groupBy'
import some from 'lodash/some'
import map from 'lodash/map'
import algoliasearch from 'algoliasearch'
import { algoliaClient, FEATURE_FLAGS } from 'src/general-config'
import {
  BuyGetSpendNames,
  ATTRIBUTE_VALUES_QUERY,
  DISCOUNT_WINDOW,
  PROMO_TYPE_FROM_EXTRA_FIELD,
  PromotionTypeOptions,
  PromoTypeRadioIds,
  PRICE_KIND_MAP,
  SINGLE_MULTIUSE,
  ALL,
} from 'src/modules/promo/constants'
import { Buffer } from 'buffer/'
import { isFeatureActive } from 'src/api/featureFlag'
import getSymbolFromCurrency from 'currency-symbol-map'
import {
  getProductsDataWithTargetXTypeV2,
  getProductsDataForOtherTargetTypeV2,
} from './productSelectionV2'
import {
  isGlobalExclusive,
  isStackable,
  isTypeExclusive,
  isUniversal,
} from '../constants/constants'
import { COUPON_TYPES } from 'src/ds4/features/couponRedemptionListing/data/constants'

const APP_ID = process.env.ALGOLIA_APP_ID || 'AWP6XJTOHO'
const ALGOLIA_API_KEY =
  process.env.ALGOLIA_API_KEY || '5c9f99984de00baad844fb1c8570aebc'
const client = algoliasearch(APP_ID, ALGOLIA_API_KEY)

const itemsSearchIndex =
  (algoliaClient && algoliaClient.attributesItemsSearchIndex) ||
  'teamfabric-copilotApi-jobs-develop-copilot-item-index'
const getVariantsIndex = client.initIndex(itemsSearchIndex)

const fetchSelectedProductVariants = async (parentId) => {
  try {
    const { hits } = await getVariantsIndex.search('', {
      page: 0,
      facetFilters: [['isParent: false'], [`parent: ${parentId}`]],
    })
    return hits.length === 0 ? [] : hits
  } catch {
    return []
  }
}
const getKeyValue = (objects, value) => {
  const result = objects.filter((obj) => {
    return obj.key === value
  })
  return result.length > 0 ? result[0].value : undefined
}
const ellipsis = (text, maxLimit) =>
  text && text.length > maxLimit
    ? text.substring(0, maxLimit - 3) + '...'
    : text
const pushToArray = (arr, obj) => {
  const index = arr.findIndex((e) => e.key === obj.key)

  if (index === -1) {
    arr.push(obj)
  } else {
    arr[index] = obj
  }
  return arr
}

const isPriceActive = (offer) => {
  const currentDateTime = new Date()
  const startDate = parseISO(offer.startDate)
  const endDate = parseISO(offer.endDate)
  return (
    isAfter(currentDateTime, startDate) && isBefore(currentDateTime, endDate)
  )
}

const isPriceUpcoming = (offer) => {
  const currentDateTime = new Date(new Date()).toISOString()
  const isUpcoming = currentDateTime <= new Date(offer.startDate).toISOString()
  return isUpcoming
}

const getPriceDuration = (offer) => {
  let duration = {}
  duration['startDate'] = format(offer.startDate, 'D MMM Y, hh:mm a')
  duration['endDate'] = format(offer.endDate, 'D MMM Y, hh:mm a')
  return duration
}

const formatDate = (value) => {
  const options = {
    day: '2-digit',
    year: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  }
  return new Date(value).toLocaleDateString('en-US', options)
}

const calculateHasMore = (data) =>
  data.query &&
  data.query.limit + data.query.offset < data.query.count &&
  data.query.count > 0

const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

const checkIfAnyPriceIsUpcomingOrActive = (offers) => {
  let isActive = false
  offers.forEach((price) => {
    if (isPriceActive(price) || isPriceUpcoming(price)) {
      isActive = true
    }
  })
  return isActive
}

const checkDataType = (data) => {
  let dataType = Object.prototype.toString.call(data)
  return dataType.substring(8, dataType.length - 1)
}

const validateDecimal = (price) => {
  let fractionalPart = price ? price.toString().split('.')[1] : ''
  if (fractionalPart && fractionalPart.length > 2) {
    return Number.parseFloat(price)
  } else {
    return Number.parseFloat(price).toFixed(2)
  }
}

const displayPriceAndRange = ({ priceRange, currency }) => {
  if (
    priceRange &&
    priceRange.min >= 0 &&
    checkDataType(priceRange.min) === 'Number'
  ) {
    if (priceRange.min === priceRange.max) {
      return `${getSymbolFromCurrency(currency)}${validateDecimal(
        priceRange.min
      )}`
    } else {
      return `${getSymbolFromCurrency(currency)}${validateDecimal(
        priceRange.min
      )} -  ${getSymbolFromCurrency(currency)}${validateDecimal(
        priceRange.max
      )}`
    }
  } else {
    return '-'
  }
}
const createCloneApiError = (e, failureMessage, catchApiFailErrors) => {
  if (e.response && e.response.data) {
    const { code, message } = e.response.data
    let toastMessage = ''
    if (
      code === 'REQUEST_VALIDATION' ||
      code === 'PROMO_CODE_EXISTS' ||
      code === 'PROMO_TITLE_EXISTS'
    ) {
      toastMessage = message
    } else if (code === 'PROMO_CHANGES_NOT_WITHIN_TIME_RANGE') {
      toastMessage =
        'Sorry, You cannot update a promotion starting from 15 mins before the promotion goes live to till it goes live but can kill the promotion by using End Now button.'
    }
    catchApiFailErrors({
      showToaster: true,
      isLoading: false,
      toasterMessage: toastMessage,
      dismissDuration: 6000,
    })
  } else {
    catchApiFailErrors({
      showToaster: true,
      isLoading: false,
      toasterMessage: failureMessage,
    })
  }
}

const groupedAndMultiSelectDataFormatting = (
  data,
  stateData,
  isDetaultSelectable,
  groupedListKey,
  groupedListValue,
  groupByAttribute = 'name'
) => {
  const groupedOptions = groupBy(data, groupByAttribute)
  return map(groupedOptions, (arr, name) => {
    const selectedOptions = stateData.find((option) => {
      return option.label === name
    })
    let MultiSelectedFormatedObj = {
      label: name,
      key: name.replace(/[^A-Z0-9]+/gi, '_'),
      [groupedListKey]: arr.map((el) => {
        return el[groupedListValue]
      }),
    }
    if (isDetaultSelectable) {
      const DefaultObj = arr.find((o) => o.isDefault)
      return {
        ...MultiSelectedFormatedObj,
        isDefault: DefaultObj ? DefaultObj.isDefault : false,
        isSelected:
          (selectedOptions && selectedOptions.isSelected) ||
          (DefaultObj ? DefaultObj.isDefault : false) ||
          false,
      }
    }
    return {
      ...MultiSelectedFormatedObj,
      isSelected: (selectedOptions && selectedOptions.isSelected) || false,
    }
  })
}
const multiSelectDataFormatting = (data, stateData, StyledLabel) => {
  return map(data, (obj) => {
    const selectedOptions = stateData.find((option) => {
      return option.label === obj.name
    })
    return {
      label: renderLabel(obj, StyledLabel),
      isSelected: (selectedOptions && selectedOptions.isSelected) || false,
      key: obj.name.replace(/[^A-Z0-9]+/gi, '_'),
      priceListIds: obj.priceListId,
      currency: obj.currency,
    }
  })
}

const renderLabel = (obj, StyledLabel) => {
  return (
    <StyledLabel>
      {`${obj.name.length > 32 ? ellipsis(obj.name, 30) : obj.name}`}
      <div className="currency">
        {`${obj.currency} ${getSymbolFromCurrency(obj.currency)}`}
      </div>
    </StyledLabel>
  )
}

const getAttributeValuesFromHits = ({ hits, key }) => {
  let newValues = []
  let attributeId = ''
  hits?.forEach((hit) => {
    hit?.attributes.forEach((attribute) => {
      if (attribute.key === key) {
        attributeId = attribute?.id
        attribute.value &&
          !newValues.includes(attribute.value) &&
          newValues.push(attribute.value)
      }
    })
  })
  return {
    id: attributeId,
    values: newValues,
  }
}

const fetchAttributeValues = async ({ name }) => {
  try {
    let { hits, nbPages } = await getVariantsIndex.search(
      '',
      ATTRIBUTE_VALUES_QUERY({ name, page: 0 })
    )
    let promises = []
    for (let i = 1; i <= nbPages; i++) {
      promises.push(
        getVariantsIndex.search('', ATTRIBUTE_VALUES_QUERY({ name, page: i }))
      )
    }
    await Promise.all(promises).then((responses) => {
      responses.forEach((response) => {
        hits = hits.concat(response.hits)
      })
    })
    return getAttributeValuesFromHits({
      key: name,
      hits: hits,
    })
  } catch (error) {
    return {
      error: error.message,
    }
  }
}

const getSelectedNameFromAttributeData = ({ selectedAttributes, name }) => {
  let isChecked = false
  Object.keys(selectedAttributes).forEach((attribute) => {
    if (selectedAttributes[attribute].name === name) {
      isChecked = true
    }
  })
  return isChecked
}

const csvReader = ({ files }, getParseData) => {
  var parseData,
    file = files[0]
  const reader = new FileReader()
  reader.onload = (e) => {
    parseData = parse(e.target.result, {
      header: true,
      skipEmptyLines: 'greedy',
      transform: (value) => value.trim(),
    })
    getParseData(parseData)
  }
  reader.readAsText(file)
}

const hexToRgba = (hex, opacity) => {
  const result = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))

  return { red: result[0], green: result[1], blue: result[2], opacity }
}

const getBuyGetTargetTypes = ({ buyGetType, promotion }) => {
  if (buyGetType === 'buy') {
    let buyTypes = []
    promotion?.[`${buyGetType}Data`]?.forEach((buy) => {
      buyTypes.push(`set${buy.set}`)
    })
    return buyTypes
  } else if (buyGetType === 'get') {
    let getTypes = []
    promotion?.[`${buyGetType}Data`]?.forEach((get) => {
      if (get.getSkuSet) {
        getTypes.push(get.getSkuSet.set)
      }
    })
    return getTypes
  } else {
    let spendTypes = []
    if (promotion?.[`${buyGetType}Data`]?.skuCondition !== '*') {
      spendTypes.push('spend')
    }
    return spendTypes
  }
}

const isPartial = ({ attribute, targetTypes, props }) => {
  return some(targetTypes, (targetType) => {
    const selectedAttributes = props[`${targetType}SelectedAttributes`]
    return (
      attribute.value &&
      selectedAttributes &&
      selectedAttributes[attribute.id] &&
      selectedAttributes[attribute.id].values &&
      selectedAttributes[attribute.id].values.length !== attribute.value.length
    )
  })
}

const getOtherTargetTypesSelectedSKus = ({ targetType, allData }) => {
  let types = []
  let otherTargetTypesSelectedSKus = []
  const { promotion } = allData
  types = types.concat(
    getBuyGetTargetTypes({ buyGetType: BuyGetSpendNames.BUY, promotion })
  )
  types = types.concat(
    getBuyGetTargetTypes({ buyGetType: BuyGetSpendNames.GET, promotion })
  )
  types = types.concat(
    getBuyGetTargetTypes({ buyGetType: BuyGetSpendNames.SPEND, promotion })
  )
  types.forEach((type) => {
    if (
      targetType !== type &&
      (targetType.slice(0, 3) === type.slice(0, 3) ||
        checkIfSetSelected(allData, type))
    ) {
      otherTargetTypesSelectedSKus = otherTargetTypesSelectedSKus.concat(
        allData[`${type}SelectedSkus`]?.map((sku) => {
          return sku.sku || ''
        }) || []
      )
    }
  })
  return otherTargetTypesSelectedSKus
}
const isFunction = (fn) => typeof fn === 'function'

const noImage =
  'https://d30qpyrmi32nba.cloudfront.net/offers/a42d451af7f0a0e677d0.png'

function getDateValidations(fields, type, globalExclusionUpdate = false) {
  if (fields[type].length <= 0) {
    return type === 'startDate' ? 'Enter the start date' : 'Enter an end date'
  }
  if (!globalExclusionUpdate) {
    if (isBefore(fields[type], new Date())) {
      return type === 'startDate'
        ? fields.hasOwnProperty('applicableOn')
          ? 'Start date & Time cannot be in the past'
          : "Start date can't be in past"
        : fields.hasOwnProperty('applicableOn')
          ? 'End date & Time cannot be in the past'
          : "End date can't be in past"
    }
  }
}

const groupByCategoryDiscount = ({ products }) => {
  const groupByCategory = products?.reduce((group, product) => {
    const { kind } = product
    group[kind] = group[kind] ?? []
    group[kind]?.push(product)
    return group
  }, {})

  let modifiedResult = []
  for (let name in groupByCategory) {
    let groupByCategory_r = groupByCategory[name]?.reduce(function (r, a) {
      r[a.discountId] = r[a?.discountId] || []
      if (r[a.discountId]?.length === 0) {
        r[a.discountId] = a
      } else {
        r[a.discountId].value =
          r[a?.discountId]?.value?.concat(a?.value) || a?.value
      }
      return r
    }, [])

    modifiedResult?.push(groupByCategory_r)
  }

  return modifiedResult?.flat()
}
const encodeId = (id) => {
  return Buffer.from(id, 'utf-8').toString('base64')
}

const decodeId = (id) => {
  return Buffer.from(id, 'base64').toString('utf-8')
}

const getSelectedSKUsHeader = ({
  selectedCriteria,
  attributeItemsDetails,
  selectedCategoriesSkusCount,
  tempSelectedSkusCount,
}) => {
  if (selectedCriteria === 'Attributes') {
    return attributeItemsDetails?.items?.length || 0
  } else {
    if (selectedCriteria === 'Categories') {
      return selectedCategoriesSkusCount
    } else {
      return tempSelectedSkusCount || 0
    }
  }
}

const emptySkuWindowMessage = ({ selectedCriteria }) => {
  if (selectedCriteria === 'Categories') {
    return 'Select category to get SKUs'
  } else if (selectedCriteria === 'Collections') {
    return 'Select collection to get SKUs'
  } else {
    return 'No search results found'
  }
}

const checkIsBuyGetSelected = (state) => {
  if (state.selectedPromoTypeRadio === 'Buy-Get') {
    return (
      state.promotion?.buyOperator ||
      (!state.promotion?.buyOperator && state.promotion?.getType !== 'CART')
    )
  }
  return false
}

const getBulkUploadCSVHeaders = (isError = false) => {
  const enhancementInBulkPriceImport = isFeatureActive({
    flagName: FEATURE_FLAGS?.ENHANCEMENT_IN_BULK_PRICE_IMPORT,
  })
  let headers = [
    enhancementInBulkPriceImport ? `*SKU ID` : `SKU ID`,
    'Cost',
    enhancementInBulkPriceImport ? '*Price' : 'Price',
    'Clearance Price',
    enhancementInBulkPriceImport ? '*Start Date(UTC)' : 'Start Date(UTC)',
    enhancementInBulkPriceImport
      ? 'PriceListName/PriceListId'
      : 'Price List Name',
  ]
  if (isError) {
    headers.push('Error')
  }
  return headers
}

export const getBulkCouponCodeCSVHeaders = (isV3Enabled, importType) => {
  const csvHeaders =
    importType === 'coupons'
      ? [
          'Title',
          'Start Date (UTC)',
          'End Date (UTC)',
          'Type',
          'Description',
          'Is stackable',
          'Stacking priority',
          'Is always applied',
          'Single/Multi-use coupon',
          'Coupon code',
          'Limit use per customer',
          'Limit use site wide',
          'Shipment methods IDs',
          'Eligible PriceList',
          'Terms and conditions title',
          'Terms and conditions description',
        ]
      : [
          isV3Enabled ? 'Coupon ID' : 'Promotion ID',
          'Coupon code',
          'User ID',
          'Status',
        ]
  return csvHeaders
}

const checkIfSetSelected = (props, data) => {
  const { promotion } = props
  const { getData } = promotion || {}
  let isSetSelected = false
  if (
    checkTruthy(getData?.length, props.targetType?.includes(DISCOUNT_WINDOW))
  ) {
    getData?.forEach((singleDiscount, i) => {
      if (
        checkTruthy(
          singleDiscount?.getSelectedSkuSets?.length,
          props.targetType !== `discount${i}`
        )
      ) {
        singleDiscount?.getSelectedSkuSets?.forEach((set) => {
          if (data.includes(set.value)) {
            isSetSelected = true
          }
        })
      }
    })
  }
  return isSetSelected
}

const checkTruthy = (condition1, condition2) => {
  return condition1 && condition2
}

const checkDisabledForGetSide = ({ props, isBuy }) => {
  const { buyData, spendData, buyOperator } = props?.promotion || {}
  const rules =
    props[`set${buyData[buyData?.length - 1]?.set}IncludeSelectedRules`]
  const spendRules = props[`spendIncludeSelectedRules`]
  const setProducts =
    props[`set${buyData[buyData?.length - 1]?.set}IncludeProducts`] ||
    props[`set${buyData[buyData?.length - 1]?.set}Products`]
  const spendProducts = props[`spendIncludeProducts`] || props[`spendProducts`]
  const list = isBuy ? setProducts : spendProducts
  const hasProducts =
    (buyOperator
      ? !buyData[0].minimumQuantity
      : !spendData?.minimumCartValue) ||
    (buyData.length === 1 &&
      list &&
      list?.length === 0 &&
      !isAllSkusSelected(isBuy ? rules : spendRules)) ||
    false
  if (props?.disabled) {
    return true
  } else if (!buyOperator) {
    if (spendData?.skuCondition === '*') {
      return !spendData?.minimumCartValue
    } else {
      return hasProducts
    }
  } else {
    return hasProducts
  }
}

export const getMinTime = (date) => {
  return date === '' || isToday(date)
    ? new Date()
    : startOfDay(parseISO(new Date()))
}

export const getMaxTime = () => {
  const parsedDate = parseISO(new Date())
  const dayStart = startOfDay(parsedDate)
  const hourlyDate = addHours(dayStart, 23)
  return addMinutes(hourlyDate, 45)
}

export const isToday = (date) => {
  const today = new Date()
  date = new Date(date)
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  )
}

export function combineDateAndTime(date, time) {
  const combinedDate = new Date(date)
  combinedDate.setHours(time.getHours())
  combinedDate.setMinutes(time.getMinutes())
  combinedDate.setSeconds(time.getSeconds())
  return combinedDate
}

const getPriceKind = (priceKind, key, discountId = '') => {
  let priceKindData = {
    [key]: 'PRICE_KIND',
    value: priceKind,
    operator: 'EQUAL',
  }
  if (discountId !== '') {
    priceKindData.discountId = discountId
  }
  return priceKindData
}

export const getPrevDiscountKind = (key, discountId) => {
  let prevDiscount = {
    [key]: 'PREVIOUS_DISCOUNTS',
    value: 0,
    operator: discountId === 1 ? 'EQUAL' : 'GREATER_THAN',
    discountId: `${discountId}`,
  }
  return prevDiscount
}

const getAllExtraFieldsFromData = (promotion) => {
  let {
    extra,
    promo,
    promoCodes,
    eligiblePriceList,
    type,
    buyOperator,
    singleMultiUse,
  } = promotion
  const couponType = promoCodes?.length > 1 ? 'isSingle' : 'isMultiple'
  let buyData = getBuyData(promotion)
  let spendData = getSpendData(promotion)
  extra.buyData = buyData.length === 0 ? '' : buyData
  extra.buyOperator =
    type === 'SPEND_GET' || type === 'CART' || type === 'SHIPPING'
      ? ''
      : buyOperator
  extra.buyXSameSku = ''
  extra.getData = getGetData(promotion)
  extra.getType = getGetType(promotion)
  extra.getYSameSku = null
  extra.isAdvanceOptionsOpen = false
  extra.selectedAdvancedOption = 'SAME_SKUS'
  extra.selectedExplicitOption = singleMultiUse
    ? SINGLE_MULTIUSE[singleMultiUse]
    : couponType
  extra.selectedPriceListOptions = eligiblePriceList?.length
    ? 'isSpecific'
    : 'isAll'
  extra.selectedEligiblePriceList = eligiblePriceList
  extra.selectedPriceRadio = promo?.length
    ? getSelectedPriceKind(promo[0])
    : 'sale-base-price'
  extra.selectedPromoTypeRadio =
    type !== 'SHIPPING'
      ? PROMO_TYPE_FROM_EXTRA_FIELD[type]
      : getPromoTypeFromPromo(promo)
  extra.spendData = Object.entries(spendData).length === 0 ? '' : spendData
  extra.userKind = 'All'
  extra.targetType = 'targetX'
  extra.promoTiers = getPromoTiersFromPromo(promotion)
  extra.target = getTargetTypesFromPromoData(promotion)
  return extra
}

const getBuyData = (promotion) => {
  let buyData = []
  const { type, promo } = promotion
  if (type === 'BUYGET') {
    buyData = []
    promo?.length &&
      promo[0]?.condition?.forEach((singleCondition) => {
        let singleBuyData = {
          set: singleCondition.set,
          minimumQuantity:
            singleCondition?.conditions?.filter((condition) => {
              if (
                condition?.key === 'QUANTITY' &&
                condition?.operator === 'GREATER_THAN_OR_EQUAL_TO'
              ) {
                return condition
              }
            })[0]?.value || 0,
        }
        buyData.push(singleBuyData)
      })
  }
  if (type === 'SPEND_GET' || type === 'CART' || type === 'SHIPPING') {
    return [
      {
        minimumQuantity: '',
        set: 'A',
      },
    ]
  }
  return buyData
}

const checkPromoType = (type) => {
  return (
    type === 'BUYGET' ||
    type === 'SPEND_GET' ||
    type === 'CART' ||
    type === 'SHIPPING'
  )
}

const getGetDiscountType = (PromotionTypeOptions, singleDiscount) => {
  let getDiscountType = {}
  PromotionTypeOptions?.forEach((option, index) => {
    if (singleDiscount?.autoAdd) {
      getDiscountType = {
        id: index,
        name: 'Free',
        label: 'Free',
      }
    } else if (option?.key === singleDiscount?.unit) {
      getDiscountType = {
        id: index,
        name: option.title,
        label: option.title,
      }
    }
  })
  return getDiscountType
}

const getGetData = (promotion) => {
  let getData = {}
  const { type, promo } = promotion
  if (checkPromoType(type)) {
    getData = []
    promo?.length &&
      promo[0]?.discount?.forEach((singleDiscount, i) => {
        let getSkuSet = getGetSkuSet(singleDiscount, i)
        let singleGetData = {
          getMaxQuantity: singleDiscount?.ON?.quantity,
          getDiscountType: getGetDiscountType(
            PromotionTypeOptions,
            singleDiscount
          ),
          getDiscountAmount: singleDiscount?.value,
          getDiscountSortBy: singleDiscount?.ON?.sortBy
            ? singleDiscount?.ON?.sortBy
            : ALL,
          getShippingType: singleDiscount?.ON?.kind?.includes('SERVICE')
            ? singleDiscount?.ON?.value
            : [],
          getSelectedSkuSets:
            promo[0]?.discount[0]?.ON?.kind !== 'SKU'
              ? []
              : getGetSelectedSkuSets(singleDiscount, promo),
          getSkuSet: Object.entries(getSkuSet).length === 0 ? '' : getSkuSet,
          isAllSelected:
            promo[0]?.condition?.length === singleDiscount?.set?.length,
          isClickedAll:
            promo[0]?.condition?.length === singleDiscount?.set?.length,
        }
        getData.push(singleGetData)
      })
  }
  return getData
}

const getGetSkuSet = (discount, i) => {
  if (!discount?.set?.length && discount?.ON?.kind === 'SKU') {
    return {
      set: `discount${i}`,
    }
  }
  return {}
}

const getGetSelectedSkuSets = (discount, promo) => {
  let getSelectedSkuSets = [
    {
      label: 'New set of SKUs (below)',
    },
  ]
  if (discount?.set?.length) {
    getSelectedSkuSets = discount?.set?.map((singleSet) => {
      return {
        id: `set${singleSet}`,
        label: `Set ${singleSet}`,
        value: `set${singleSet}`,
        optionChecked: true,
      }
    })
    if (promo[0]?.condition?.length === getSelectedSkuSets?.length) {
      let firstIndex = 'All (SKU set '
      getSelectedSkuSets?.forEach((data) => {
        firstIndex += `${data?.id?.replace('set', '')}&`
      })
      firstIndex = firstIndex.slice(0, firstIndex.length - 1)
      firstIndex += ')'
      getSelectedSkuSets.push({ id: 'All', label: firstIndex, value: 'All' })
    }
  }
  return getSelectedSkuSets
}

const getGetType = (promotion) => {
  const { type, promo } = promotion
  if (
    type === 'BUYGET' ||
    type === 'SPEND_GET' ||
    type === 'CART' ||
    type === 'SHIPPING'
  ) {
    if (promo[0]?.discount[0]?.ON?.kind === 'SERVICE_CART') {
      return 'SHIPPING'
    } else {
      return promo[0]?.discount[0]?.ON?.kind
    }
  }
  return ''
}

const getUIPriceKind = (priceKind) => {
  for (let key of Object.keys(PRICE_KIND_MAP)) {
    if (PRICE_KIND_MAP[key] === priceKind) {
      return key
    }
  }

  return 'sale-base-price'
}
const getSelectedPriceKind = ({ targetProducts = [], condition }) => {
  let priceKind = targetProducts.find((obj) => obj.kind === 'PRICE_KIND')?.value

  if (priceKind) {
    return getUIPriceKind(priceKind)
  }

  priceKind = condition[0]?.conditions.find(
    (condition) => condition.key === 'PRICE_KIND'
  )?.value
  return getUIPriceKind(priceKind)
}

const getSpendData = (promotion) => {
  const { type, promo } = promotion
  const conditions = promo[0]?.condition[0]?.conditions
  let skuCondition = '*'
  conditions?.forEach((condition) => {
    if (
      condition?.value !== '*' &&
      (condition?.key === 'SKU' ||
        condition?.key === 'CATEGORY' ||
        condition?.key === 'COLLECTION' ||
        condition?.key === 'ATTRIBUTE')
    ) {
      if (condition?.operator === 'IN') {
        skuCondition = 'INCLUDE'
      } else if (condition?.operator === 'NOT_IN') {
        skuCondition = 'EXCLUDE'
      }
    }
  })
  if (type === 'SPEND_GET' || type === 'CART' || type === 'SHIPPING') {
    return {
      minimumCartValue: conditions?.filter(
        (condition) =>
          condition?.key === 'ORDER_VALUE' &&
          condition?.operator === 'GREATER_THAN_OR_EQUAL_TO'
      )[0]?.value,
      skuCondition: skuCondition,
      spendSkuSet: '',
    }
  }
  return {}
}

const getPromoTypeFromPromo = (promo) => {
  let promoType = PROMO_TYPE_FROM_EXTRA_FIELD.PRODUCT
  promo?.length &&
    promo[0]?.discount?.forEach((singleDiscount) => {
      if (singleDiscount?.ON?.kind === 'SERVICE_CART') {
        promoType = PROMO_TYPE_FROM_EXTRA_FIELD.QUANTITY
      }
    })
  promo?.length &&
    promo[0]?.condition[0]?.conditions?.forEach((singleCondition) => {
      if (singleCondition?.key === 'ORDER_VALUE') {
        promoType = PROMO_TYPE_FROM_EXTRA_FIELD.SPEND_GET
      }
    })
  return promoType
}

const getPromoTiersFromPromo = (promotion) => {
  const { promo, type } = promotion
  const isBuyGetType = type === 'BUYGET'
  let promoTiers = []
  promo?.forEach((singlePromo, index) => {
    const sortBy = singlePromo?.discount[0]?.ON?.sortBy
    let isShipping = promo[index]?.discount[0]?.ON?.kind.includes('SERVICE')
    let promoTier = {
      id: index,
      typeWithValues: {
        promotionTypeValue: !isShipping ? singlePromo?.discount[0]?.value : '',
        selectedSortBy: sortBy ? sortBy : ALL,
        promotionTypeValueIfDiscountExist:
          !isShipping && !isBuyGetType && singlePromo?.discount.length > 1
            ? singlePromo?.discount[1]?.value
            : '',
        dynamicDiscount: !isBuyGetType && singlePromo?.discount.length > 1,
        ...getShippingPromoTypeData(promotion, index, isShipping, isBuyGetType),
      },
    }
    singlePromo?.condition[0]?.conditions?.forEach((singleCondition) => {
      if (
        singleCondition?.key === 'QUANTITY' &&
        singleCondition?.operator === 'GREATER_THAN_OR_EQUAL_TO'
      ) {
        promoTier.buyValue = singleCondition?.value
      }
    })
    !isShipping &&
      PromotionTypeOptions?.forEach((option) => {
        if (option?.key === singlePromo?.discount[0]?.unit) {
          promoTier.typeWithValues.selectedPromotionType = option.title
        }
      })
    promoTiers.push(promoTier)
  })
  return promoTiers
}

const getShippingPromoTypeData = (
  promotion,
  index,
  isShipping,
  isBuyGetType
) => {
  const { promo } = promotion
  let promoTypeOption = PromotionTypeOptions?.find(
    (option) => option?.key === promo[index]?.discount[0]?.unit
  )

  if (
    promoTypeOption?.key === '%OFF' &&
    promo[index]?.discount[0]?.unit === '%OFF' &&
    promo[index]?.discount[0]?.value === 100
  ) {
    promoTypeOption = { ...promoTypeOption, title: 'Free' }
  }
  return {
    selectedPromotionType: 'Shipping off',
    selectedShippingPromotionType: isShipping
      ? {
          id: 0,
          name: promoTypeOption?.title,
        }
      : 'Percentage off',
    selectedShippingType: isShipping
      ? promo[index]?.discount[0]?.ON?.value
      : [],
    shippingPromotionTypeValue: isShipping
      ? promo[index]?.discount[0]?.value
      : '',
    shippingPromotionTypeValueIfDiscountExist:
      isShipping && !isBuyGetType && promo[index]?.discount.length > 1
        ? promo[index]?.discount[1]?.value
        : '',
  }
}

const getTargetTypesFromPromoData = (promotion) => {
  return {
    types: getProductsDataWithTargetType(promotion),
  }
}

const getProductsDataWithTargetType = (promotion) => {
  let { extra, promo, type } = promotion
  const { products } = extra
  let isTargetTypeX = true
  if (
    PROMO_TYPE_FROM_EXTRA_FIELD[type] === 'Buy-Get' ||
    getPromoTypeFromPromo(promo) === 'Buy-Get'
  ) {
    isTargetTypeX = false
    let allTargetTypes = []
    const isNewProductSelectionEdit = promo[0]?.condition[0]?.includeOperator
    promo[0]?.condition?.forEach((singleCondition) => {
      let checkAllInSpend = false
      singleCondition?.conditions?.forEach((condition) => {
        if (condition?.key === 'SKU' && condition?.value === '*') {
          checkAllInSpend = !isNewProductSelectionEdit
        }
      })
      if (type === 'SPEND_GET' || type === 'CART' || type === 'SHIPPING') {
        if (!checkAllInSpend) {
          allTargetTypes.push('spend')
        }
      } else {
        allTargetTypes.push(`set${singleCondition?.set}`)
      }
    })
    promo[0]?.discount?.forEach((singleDiscount, index) => {
      if (
        singleDiscount?.ON?.kind === 'SKU' &&
        singleDiscount?.set?.length === 0
      ) {
        allTargetTypes.push(`discount${index}`)
      }
    })
    let allTargetTypesData = {}
    allTargetTypes?.forEach((targetType) => {
      if (
        isFeatureActive({ flagName: FEATURE_FLAGS.NEW_PRODUCT_SELECTION }) &&
        isNewProductSelectionEdit
      ) {
        allTargetTypesData[targetType] = getProductsDataForOtherTargetTypeV2({
          promotion,
          targetType,
        })
      } else {
        allTargetTypesData[targetType] = {
          attributesItemDetails: getAttributesItemDetails(extra, targetType),
          categoriesSkusCount: getCategoriesSkuCount(promotion, targetType),
          collectionsSkusCount: getCollectionsSkuCount(promotion, targetType),
          products: getProductsFromExtra({ promotion, extra, targetType }),
          radioId: 'INCLUDE_PRODUCTS',
          selectedAttributes: products?.attributes,
          selectedAttributesTree: createAttributeTree({
            attributes: products?.attributes,
            targetType,
          }),
          selectedCategories: createCategoryTree({
            promotion,
            values: products?.categories,
            targetType,
            type: 'CATEGORY',
          }),
          selectedCollections: createCategoryTree({
            promotion,
            values: products?.collections,
            targetType,
            type: 'COLLECTION',
          }),
          selectedSkus: createSKUsTree({
            promotion,
            values: products?.skus,
            targetType,
          }),
          skusCount: isTargetTypeX
            ? extra?.totalProductsCount
            : getValuesForSet(promotion, targetType, 'SKU')?.length,
          totalProductsCount: getTotalProductsCountForASet(
            promotion,
            targetType
          ),
        }
      }
    })
    return allTargetTypesData
  } else {
    const isNewProductSelectionEdit = promo[0]?.condition[0]?.includeOperator
    if (
      isFeatureActive({ flagName: FEATURE_FLAGS.NEW_PRODUCT_SELECTION }) &&
      isNewProductSelectionEdit
    ) {
      return getProductsDataWithTargetXTypeV2({ promotion })
    } else {
      return {
        targetX: {
          attributesItemDetails: getAttributesItemDetails(extra),
          categoriesSkusCount: extra.categoriesSkusCount,
          collectionsSkusCount: extra.collectionsSkusCount,
          products: getProductsFromExtra({ extra }),
          radioId: getRadioIdFromPromoData(promotion),
          selectedAttributes: products?.attributes,
          selectedAttributesTree: createAttributeTree({
            attributes: products?.attributes,
          }),
          selectedCategories: createCategoryTree({
            promotion,
            values: products?.categories,
            type: 'CATEGORY',
          }),
          selectedCollections: createCategoryTree({
            promotion,
            values: products?.collections,
            type: 'COLLECTION',
          }),
          selectedSkus: createSKUsTree({ values: products?.skus }),
          skusCount: extra?.totalProductsCount,
          totalProductsCount:
            extra?.categoriesSkusCount +
            extra?.collectionsSkusCount +
            extra?.totalProductsCount +
            getAttributesItemDetails(extra)?.totalProducts,
        },
      }
    }
  }
}

const getTotalProductsCountForASet = (promotion, targetType) => {
  const { extra } = promotion
  let selectedSkus = getValuesForSet(promotion, targetType, 'SKU')
  return (
    selectedSkus?.length +
    getCategoriesSkuCount(promotion, targetType) +
    getCollectionsSkuCount(promotion, targetType) +
    getAttributesItemDetails(extra, targetType)?.totalProducts
  )
}

const getSelectedValuesFromCondition = (condition, key) => {
  let selectedValues = []
  condition?.conditions?.forEach((singleCondition) => {
    if (singleCondition?.key === key) {
      selectedValues = singleCondition?.value
    }
  })
  return selectedValues
}

const getValuesForBuyAndSpend = ({ targetType, promo, key }) => {
  let selectedValues = []
  if (targetType.includes('set') || targetType === 'spend') {
    promo[0]?.condition?.forEach((condition) => {
      if (targetType.includes(condition?.set) || targetType === 'spend') {
        selectedValues = getSelectedValuesFromCondition(condition, key)
      }
    })
  }
  return selectedValues
}

const getValuesForSet = (promotion, targetType, key) => {
  const { promo } = promotion
  let selectedValues = []
  selectedValues = getValuesForBuyAndSpend({ targetType, promo, key })
  if (targetType.includes('discount')) {
    promo[0]?.targetProducts?.forEach((targetProduct) => {
      if (
        targetType.includes(targetProduct?.discountId) &&
        targetProduct?.kind === key
      ) {
        selectedValues = targetProduct?.value
      }
    })
  }
  return selectedValues
}

export const getCategoriesSkuCount = (promotion, targetType) => {
  const { extra } = promotion
  const { products } = extra
  const { categories } = products
  let categoriesSkusCount = 0
  let selectedCategories = getValuesForSet(promotion, targetType, 'CATEGORY')
  categories?.forEach((category) => {
    if (selectedCategories.includes(category?.id)) {
      categoriesSkusCount += category?.categorySkuCount
    }
  })
  return categoriesSkusCount
}
const getCollectionsSkuCount = (promotion, targetType) => {
  const { extra } = promotion
  const { products } = extra
  const { collections } = products
  let collectionsSkusCount = 0
  let selectedCollections = getValuesForSet(promotion, targetType, 'COLLECTION')
  collections?.forEach((collection) => {
    if (selectedCollections.includes(collection?.id)) {
      collectionsSkusCount += collection?.collectionSkuCount
    }
  })
  return collectionsSkusCount
}

const createCategoryTree = ({
  promotion,
  values,
  targetType = 'targetX',
  type,
}) => {
  let tree = {}
  let selectedCategories = {}
  if (targetType !== 'targetX') {
    selectedCategories = getValuesForSet(promotion, targetType, type)
    values = values?.filter((value) => selectedCategories.includes(value.id))
  }
  values?.forEach((value) => {
    let { title } = value
    const categoryMap =
      type === 'CATEGORY' ? value?.categoryMap : value?.collectionMap
    const categoryPath = categoryMap[title?.split('/')[0]]
    if (!tree[categoryPath]) {
      tree[categoryPath] = getCategoryBranch({
        value,
        title,
        type,
      })
    } else {
      getCategoryBranch({
        value,
        title,
        catgeoryBranch: tree[categoryPath],
        type,
      })
    }
  })
  return tree
}

const getCategoryBranch = ({ title, value, catgeoryBranch, type }) => {
  let hasChild = title?.includes('/')
  let parentTitle = hasChild ? title.split('/')[0] : title
  const categoryMap =
    type === 'CATEGORY' ? value?.categoryMap : value?.collectionMap
  let isPartialChecked = hasChild && value?.id !== categoryMap[parentTitle]
  let branch = catgeoryBranch
    ? catgeoryBranch
    : {
        _id: categoryMap[parentTitle],
        name: parentTitle,
        isChecked: !isPartialChecked,
        parentId: '',
        firstParent: '',
        isChild: true,
        count: 0,
        totalCount: 0,
        isPartialChecked: isPartialChecked,
        path: parentTitle,
      }

  if (hasChild) {
    let childTitle = title.replace(`${parentTitle}/`, '')
    const childBranch = categoryMap[title.split('/')[1]]
    if (!branch[childBranch]) {
      branch[childBranch] = getCategoryBranch({
        title: childTitle,
        value,
        type,
      })
    } else {
      getCategoryBranch({
        title: childTitle,
        value,
        catgeoryBranch: branch[childBranch],
        type,
      })
    }
  }
  return branch
}

const createSKUsTree = ({ promotion, values, targetType = 'targetX' }) => {
  let tree = {}
  let selectedSkus = {}
  if (targetType !== 'targetX') {
    selectedSkus = getValuesForSet(promotion, targetType, 'SKU')
    values = values?.filter((value) => selectedSkus.includes(value?.itemId))
  }
  values?.forEach((value) => {
    tree[value?.productId] = {
      ...value,
      sku: value?.productSku,
      parent: value?.parentSku,
      _id: value?.productId,
      images: [],
    }
  })
  return tree
}

export const createAttributeTree = ({ attributes, targetType = 'targetX' }) => {
  let tree = {}
  if (targetType === 'spend' || targetType === 'targetX') {
    attributes?.forEach((attribute) => {
      tree[attribute?.parent] = {
        name: attribute?.parent,
        values: tree[attribute?.parent]?.values
          ? tree[attribute?.parent]?.values.concat([attribute?.id])
          : [attribute?.id],
      }
    })
  }
  return tree
}

export const getAttributesItemDetails = (extra, targetType = 'targetX') => {
  let attributesItemDetails = {
    currentPage: 0,
    hasMore: true,
    maxPage: 1,
    searchParameter: '',
  }
  let totalProducts = 0
  let items = []
  if (targetType === 'targetX' || targetType === 'spend') {
    extra?.attributesItemDetails?.forEach((details) => {
      items = items.concat(details?.items)
      totalProducts = totalProducts + details?.itemCount
    })
  }
  attributesItemDetails.items = items
  attributesItemDetails.totalProducts = totalProducts
  return attributesItemDetails
}

const getRadioIdFromPromoData = (promotion) => {
  const { promo } = promotion
  let radioId = PromoTypeRadioIds.all

  promo?.length &&
    promo[0]?.targetProducts?.forEach((targetProduct) => {
      if (
        targetProduct?.value !== '*' &&
        (targetProduct?.kind === 'SKU' ||
          targetProduct?.kind === 'COLLECTION' ||
          targetProduct?.kind === 'CATEGORY' ||
          targetProduct?.kind === 'ATTRIBUTE')
      ) {
        if (targetProduct?.operator === 'IN') {
          radioId = PromoTypeRadioIds.include
        } else if (targetProduct?.operator === 'NOT_IN') {
          radioId = PromoTypeRadioIds.exclude
        }
      }
    })
  return radioId
}

export const isAllSkusSelected = (rules = []) =>
  rules?.length && rules[0]?.value === 'All'

export const isIncludeProductAdded = ({ rules, products }) => {
  return isAllSkusSelected(rules) || products?.length
}

export const isGetDiscountSkuTypeOnly = ({
  includeRules = [],
  excludeRules = [],
}) => {
  const targetConditions = ['Categories', 'Collections', 'Attributes']
  const isCatgeoryAttributesSelected = [...includeRules, ...excludeRules].find(
    (rule) => targetConditions.includes(rule.value)
  )
  return !isCatgeoryAttributesSelected
}

const getProductsFromExtra = ({ promotion, extra, targetType = 'targetX' }) => {
  const { products } = extra
  let createdProducts = []
  let selectedCategories = []
  let selectedSkus = []
  if (targetType !== 'targetX') {
    selectedCategories = getValuesForSet(promotion, targetType, 'CATEGORY')
    selectedSkus = getValuesForSet(promotion, targetType, 'SKU')
    selectedCategories?.length &&
      createdProducts.push({
        key: 'Categories',
        values: products?.categories?.filter((category) =>
          selectedCategories.includes(category?.id)
        ),
        count: selectedCategories?.length,
      })
    selectedSkus?.length &&
      createdProducts.push({
        key: 'SKUs',
        values: products?.skus.filter((sku) => {
          if (selectedSkus.includes(sku?.itemId)) {
            return {
              ...sku,
              sku: sku?.productSku,
            }
          }
        }),
        count: selectedSkus?.length,
      })
  }
  targetType === 'targetX' &&
    products?.skus?.length &&
    createdProducts.push({
      key: 'SKUs',
      values: products?.skus.map((sku) => {
        return {
          ...sku,
          sku: sku?.productSku,
        }
      }),
      count: products?.skus?.length,
    })
  targetType === 'targetX' &&
    products?.categories?.length &&
    createdProducts.push({
      key: 'Categories',
      values: products?.categories,
      count: products?.categories?.length,
    })
  products?.collections?.length &&
    createdProducts.push({
      key: 'Collections',
      values: products?.collections,
      count: products?.collections?.length,
    })
  products?.attributes?.length &&
    createdProducts.push({
      key: 'Attributes',
      values: products?.attributes,
      count: products?.attributes?.length,
    })
  return createdProducts
}

const isIdV2Account = localStorage.getItem('isLoggedInWithIdV2') === 'true'

let isArrayEmpty = (items) => {
  return items === null || items === undefined || items.length === 0
}

function take(n, iterable) {
  const iter = iterable[Symbol.iterator]()
  return {
    [Symbol.iterator]() {
      return this
    },
    next() {
      if (n > 0) {
        n--
        return iter.next()
      } else {
        return { done: true }
      }
    },
  }
}

let productSelectionSortOrderMap = {
  skus: 0,
  category: 1,
  collection: 2,
  attribute: 3,
}

function addSortOrderForDisplay(productTargets) {
  return productTargets.map((productTarget) => ({
    ...productTarget,
    sortOrder: productSelectionSortOrderMap[productTarget.id],
  }))
}

const getSelectedStackingType = ({ level, stackable, isAlwaysApplied }) => {
  if (isAlwaysApplied) {
    return isUniversal
  } else if (stackable) {
    return isStackable
  } else if (!level) {
    return isGlobalExclusive
  } else if (level && !stackable) {
    return isTypeExclusive
  }
}

const getNavigationLinks = (links) => {
  if (
    isFeatureActive({ flagName: FEATURE_FLAGS.REDEMPTION_EXPORT }) === false
  ) {
    return links.map((link) => {
      if (link.id === 3) {
        let { children, ...couponLink } = link
        couponLink.url = '/offers/coupon-list'
        return couponLink
      }

      return link
    })
  }

  return links
}

function isOnlyTodayDate(from, to) {
  return from && !to
}

function getFromToRedemptionDateStrings(from, to) {
  let fromStr = ''
  let toStr = ''

  if (!from && !to) {
    return { fromStr, toStr }
  }

  if (from) {
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    from.setMilliseconds(0)
  }
  fromStr = from.toISOString()

  if (isOnlyTodayDate(from, to)) {
    let tomorrow = startOfDay(
      add(new Date(from), {
        days: 1,
      })
    )
    toStr = tomorrow.toISOString()
  } else {
    let toDate = startOfDay(
      add(new Date(to), {
        days: 1,
      })
    )
    toStr = toDate.toISOString()
  }

  return { fromStr, toStr }
}
const translateToCreateExportRequest = ({ searchCriterion, coupons }) => {
  let request = []
  if (searchCriterion) {
    for (let key of Object.keys(searchCriterion)) {
      if (
        searchCriterion[key] &&
        key.toLowerCase() !== 'coupontitle' &&
        key.toLowerCase() !== 'redemptiondate' &&
        key.toLowerCase() !== 'couponstatuses'
      ) {
        let param = {
          field: key,
          value: searchCriterion[key],
          operator: 'IN',
        }
        request.push(param)
      }
      if (
        searchCriterion[key] &&
        key.toLowerCase() === 'redemptiondate' &&
        (searchCriterion[key].from || searchCriterion[key].to)
      ) {
        let { from, to } = searchCriterion[key]
        let { fromStr, toStr } = getFromToRedemptionDateStrings(from, to)
        let param = {
          field: 'redeemedAt',
          value: fromStr,
          operator: 'GREATER_THAN_OR_EQUAL_TO',
        }
        request.push(param)
        param = {
          field: 'redeemedAt',
          value: toStr,
          operator: 'LESS_THAN',
        }
        request.push(param)
      }
    }
  }
  if (coupons && coupons.length > 0) {
    let param = {
      field: 'promoId',
      value: [...coupons],
      operator: 'IN',
    }
    request.push(param)
  }

  return { type: 'REDEMPTION', filters: request }
}

const getRedemptionsListV3Request = ({ searchCriteria, pageNumber }) => {
  const {
    couponTitle,
    redemptionDate,
    couponTypes,
    couponStatuses,
    couponCode,
    customerId,
    emailId,
    orderNo,
    storeId,
  } = searchCriteria
  let request = {
    search: couponTitle?.trim(),
    size: 10,
    offset: (pageNumber - 1) * 10,
  }
  if (couponStatuses?.length > 0) {
    request.status = couponStatuses
      .map((status) => status.toUpperCase())
      .join(',')
  }
  if (couponTypes && couponTypes.length === 1) {
    request.singleMultiUse =
      couponTypes[0] === COUPON_TYPES.SINGLE_USE ? 'SINGLE_USE' : 'MULTI_USE'
  }
  const redemptionFilter = {
    ...(couponCode && { couponCode }),
    ...(customerId && { userId: customerId }),
    ...(emailId && { email: emailId }),
    ...(orderNo && { orderId: orderNo }),
    ...(storeId && { storeId }),
    ...(redemptionDate && { redemptionDate }),
  }
  if (Object.keys(redemptionFilter).length > 0) {
    request = {
      ...request,
      redemptionFilter: { size: 10, ...redemptionFilter },
    }
  }
  return request
}

const getRedemptionFiters = (searchCriteria) => {
  let filter = { size: 10 }
  let { promoCode, userId, email, orderId, storeId, redemptionDate } =
    searchCriteria

  if (promoCode) {
    filter.promoCode = promoCode?.trim()
  }

  if (userId) {
    filter.userId = userId?.trim()
  }

  if (email) {
    filter.email = email?.trim()
  }

  if (orderId) {
    filter.orderId = orderId?.trim()
  }

  if (storeId) {
    filter.storeId = storeId?.trim()
  }
  let { fromStr, toStr } = getFromToRedemptionDateStrings(
    redemptionDate?.from,
    redemptionDate?.to
  )

  if (fromStr) {
    filter.redeemedFrom = fromStr
  }

  if (toStr) {
    filter.redeemedTo = toStr
  }

  return filter
}

export {
  fetchSelectedProductVariants,
  getKeyValue,
  pushToArray,
  isPriceActive,
  isPriceUpcoming,
  getPriceDuration,
  formatDate,
  calculateHasMore,
  capitalizeFirstLetter,
  checkIfAnyPriceIsUpcomingOrActive,
  checkDataType,
  displayPriceAndRange,
  createCloneApiError,
  csvReader,
  groupedAndMultiSelectDataFormatting,
  multiSelectDataFormatting,
  ellipsis,
  hexToRgba,
  isFunction,
  noImage,
  getDateValidations,
  getAttributeValuesFromHits,
  fetchAttributeValues,
  getBuyGetTargetTypes,
  getOtherTargetTypesSelectedSKus,
  isPartial,
  groupByCategoryDiscount,
  getSelectedNameFromAttributeData,
  encodeId,
  decodeId,
  getSelectedSKUsHeader,
  emptySkuWindowMessage,
  checkIsBuyGetSelected,
  getBulkUploadCSVHeaders,
  checkDisabledForGetSide,
  renderLabel,
  checkIfSetSelected,
  getPriceKind,
  getAllExtraFieldsFromData,
  isIdV2Account,
  isArrayEmpty,
  take,
  addSortOrderForDisplay,
  getSelectedStackingType,
  getNavigationLinks,
  translateToCreateExportRequest,
  getFromToRedemptionDateStrings,
  getRedemptionFiters,
  getRedemptionsListV3Request,
}
