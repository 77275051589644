"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REDEMPTION_DATE = exports.COUPON_STAUS_OPTIONS = exports.COUPON_STATUS = exports.COUPON_TYPE_OPTIONS = exports.COUPON_TYPES = exports.PAGE_LIMIT = exports.ERROR_FETCHING = exports.INITIAL_PAGE = exports.READ_PERMISSIONS = exports.CREATE_PERMISSIONS = exports.TABLE_COUPON_MAP = exports.TABLE_HEADERS = void 0;
var TABLE_HEADERS = function (isV3APIEnabled) { return [
    {
        title: "Coupon ".concat(isV3APIEnabled ? 'name' : 'title'),
        sortable: true,
        width: 300,
    },
    {
        title: 'Coupon code',
        width: 150,
    },
    {
        title: 'Coupon type',
        width: 150,
    },
    {
        title: 'Redemption date',
        width: 150,
    },
    {
        title: 'User ID',
        width: 150,
    },
    {
        title: 'Email ID',
        width: 150,
    },
    {
        title: 'Order no.',
        width: 150,
    },
    {
        title: 'Store ID',
        width: 150,
    },
    {
        title: '',
        width: 75,
    },
]; };
exports.TABLE_HEADERS = TABLE_HEADERS;
exports.TABLE_COUPON_MAP = {
    'Coupon title': 'title',
};
exports.CREATE_PERMISSIONS = {
    CREATE_COUPONS: 'copilot:v1:offers.coupons:create',
};
exports.READ_PERMISSIONS = {
    READ_COUPONS: 'copilot:v1:offers.coupons:read',
};
exports.INITIAL_PAGE = 1;
exports.ERROR_FETCHING = 'Error fetching coupon redemptions list';
exports.PAGE_LIMIT = 10;
exports.COUPON_TYPES = {
    SINGLE_USE: 'Single use',
    MULTIPLE_USE: 'Multiple use',
};
exports.COUPON_TYPE_OPTIONS = [
    {
        label: exports.COUPON_TYPES.SINGLE_USE,
        expanded: false,
        id: exports.COUPON_TYPES.SINGLE_USE,
        children: [],
        selected: false,
    },
    {
        label: exports.COUPON_TYPES.MULTIPLE_USE,
        expanded: false,
        id: exports.COUPON_TYPES.MULTIPLE_USE,
        children: [],
        selected: false,
    },
];
exports.COUPON_STATUS = {
    ACTIVE: 'Active',
    EXPIRED: 'Expired',
};
exports.COUPON_STAUS_OPTIONS = [
    {
        label: exports.COUPON_STATUS.ACTIVE,
        expanded: false,
        id: exports.COUPON_STATUS.ACTIVE,
        children: [],
        selected: false,
    },
    {
        label: exports.COUPON_STATUS.EXPIRED,
        expanded: false,
        id: exports.COUPON_STATUS.EXPIRED,
        children: [],
        selected: false,
    },
];
exports.REDEMPTION_DATE = 'Redemption Date';
