"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DATEPICKER_FORMAT = exports.CTA_VARIANTS = exports.APP_BREADCRUMB_LABELS = exports.APP_PAGE_PATHS = exports.FEATURE_FLAGS = exports.allDetailedFeatures = exports.featureFlagEnvMapping = exports.featureFlagDomainMapping = exports.xApiKey = void 0;
exports.xApiKey = process.env.PIM_CONNECTOR_X_API_KEY;
exports.featureFlagDomainMapping = {
    dev02: 'dev',
    stg02: 'stage',
    sandbox: 'sandbox',
    prod01: 'prod',
};
exports.featureFlagEnvMapping = {
    dev02: 'development',
    stg02: 'development',
    sandbox: 'production',
    prod01: 'production',
};
exports.allDetailedFeatures = {
    pim_connector: true,
    global_exclusions: true,
    'spend-get': true,
    promotion_limit: true,
    enhancement_in_bulk_price_import: false,
    pagination_on_product_price: true,
    disable_cart_shipping: false,
    rbac_v2: true,
};
exports.FEATURE_FLAGS = {
    PIM_CONNECTOR: 'pim_connector',
    Pim_Connector: 'pim_connector',
    TM_DOMAIN_POINTING: 'TM-domain-pointing',
    GLOBAL_EXCLUSIONS: 'global_exclusions',
    FEATURE_FLAGS: 'feature_flag',
    OMS_FEATURE_FLAGS: 'oms_feature_flag',
    SPEND_GET: 'spend-get',
    PROMOTION_LIMIT: 'promotion_limit',
    ENHANCEMENT_IN_BULK_PRICE_IMPORT: 'enhancement_in_bulk_price_import',
    PAGINATION_ON_PRODUCT_PRICE: 'pagination_on_product_price',
    DISABLE_CART_SHIPPING: 'disable_cart_shipping',
    COUPON_BULK_IMPORT: 'coupon_bulk_import',
    RBAC_V2: 'rbac_v2',
    SECOND_MOST_EXPENSIVE_ITEM: 'second_most_expensive_item',
    DS4: 'ds4',
    PIM_V1: 'pim-v1',
    SINGLE_COUPON_MULTIUSE: 'single_coupon_multiuse',
    PROMOTION_MESSAGING: 'promotion_messaging',
    PROMOTION_PROXIMITY: 'promotion_proximity',
    ATTRIBUTES_SEGMENT_API_OFFSET: 'attributes_segments_api_offset',
    OFFERS_V3_API_MIGRATION: 'offers_v3_api_migration',
    SHIPPING_ONLY_CONFIG: 'ui_shipping_only_config',
    MERCHANT_IDENTIFIER_SKU: 'isMerchantIdentifierSku',
    OMS_V1_SHIPPING_TYPE_API: 'ui-v1',
    GLOBAL_EXCLUSION_REWARD: 'ui_global_exclusion_reward',
    MULTI_CHANNEL: 'ui_multi_channel',
    UI_DELETE_ACTION_FEATURE: 'ui_delete_action_feature',
    UI_BUY_GET_V2: 'ui_buy_get_v2',
    UI_MULTI_DISCOUNT_SKU: 'ui_multi_discount_sku',
    OMS_MT_URL_UPDATE: 'oms_mt_url_update',
    PROMO_FORM_REDESIGN: 'promoFormNewDesign',
    SHOW_REDEMPTION_COUNT: 'show-redemption-count',
    DATE_FILTER_IMPORT_HISTORY: 'date_filter_import_history',
    STACKING_PRIORITY_NEW: 'stacking2.1',
    MIGRATE_CATALOG_CONNECTOR: 'migration_catalog_connector',
    ABAC: 'attributeAccessControl',
    IS_CHICOS_ACCOUNT: 'isChicosAccount',
};
var APP_PAGE_PATHS;
(function (APP_PAGE_PATHS) {
    APP_PAGE_PATHS["PRODUCT_LISTING"] = "/offers/price/product-list";
    APP_PAGE_PATHS["PROMO_LISTING"] = "/offers/promotions";
    APP_PAGE_PATHS["PRICE_IMPORT_HISTORY"] = "offers/price/product-list/history/import";
    APP_PAGE_PATHS["OLD_COUPON_IMPORT_HISTORY"] = "offers/coupons/coupon-import-history";
    APP_PAGE_PATHS["PRICE_DETAILS"] = "/offers/price/product-list/:productId/:priceListId";
    APP_PAGE_PATHS["VARIANT_DETAILS"] = "/offers/price/product-list/:productId/variant/:variantId/:priceListId";
    APP_PAGE_PATHS["COUPON_LISTING"] = "/offers/coupons";
    APP_PAGE_PATHS["ALL_COUPONS"] = "/offers/coupons/all-coupons";
    APP_PAGE_PATHS["COUPON_CREATE"] = "/offers/coupons/all-coupons/coupon-create";
    APP_PAGE_PATHS["COUPON_CLONE"] = "/offers/coupons/all-coupons/coupon-clone/:couponId";
    APP_PAGE_PATHS["COUPON_UPDATE"] = "/offers/coupons/all-coupons/coupon-edit/:promoId";
    APP_PAGE_PATHS["OLD_COUPON_CREATE"] = "/offers/coupons/coupon-create";
    APP_PAGE_PATHS["OLD_COUPON_CLONE"] = "/offers/coupons/coupon-clone/:couponId";
    APP_PAGE_PATHS["OLD_COUPON_UPDATE"] = "/offers/coupons/coupon-edit/:promoId";
    APP_PAGE_PATHS["PROMO_CREATE"] = "/offers/promotions/promo-create";
    APP_PAGE_PATHS["PROMO_UPDATE"] = "/offers/promotions/promo-edit/:promoId";
    APP_PAGE_PATHS["PROMO_CLONE"] = "/offers/promotions/promo-clone/:promoId";
    APP_PAGE_PATHS["SETTINGS"] = "/offers/settings";
    APP_PAGE_PATHS["SETTINGS_PRICE_LIST"] = "/offers/settings/price-list";
    APP_PAGE_PATHS["SETTINGS_GLOBAL_EXCLUSIONS_LISTING"] = "/offers/settings/exclusions-list";
    APP_PAGE_PATHS["SETTINGS_ATTRIBUTES"] = "/offers/settings/attributes";
    APP_PAGE_PATHS["CREATE_GLOBAL_EXCLUSIONS_LIST"] = "/offers/settings/exclusions-create";
    APP_PAGE_PATHS["UPDATE_GLOBAL_EXCLUSIONS_LIST"] = "/offers/settings/exclusions-edit/:listId";
    APP_PAGE_PATHS["SETTINGS_CUSTOMER_SEGMENTS"] = "/offers/settings/customer-segments";
    APP_PAGE_PATHS["CUSTOMER_SEGMENTS"] = "/offers/settings/customer-segments";
    APP_PAGE_PATHS["HOME"] = "/home/activity";
    APP_PAGE_PATHS["OFFERS_HOME"] = "/offers/price/product-list";
    APP_PAGE_PATHS["COUPON_REDEMPTION_LISTING"] = "/offers/coupons/redemption";
    APP_PAGE_PATHS["COUPON_REDEMPTION_EXPORT_LISTING"] = "/offers/coupons/redemption/export-history";
    APP_PAGE_PATHS["COUPON_REDEMPTION_IMPORT_LISTING"] = "/offers/coupons/redemption/import-history";
    APP_PAGE_PATHS["COUPON_IMPORT_HISTORY"] = "/offers/coupons/all-coupons/coupon-import-history";
    APP_PAGE_PATHS["COUPON_IMPORT_HISTORY_DETAILS"] = "/offers/coupons/all-coupons/coupon-import-history/:fileId";
    APP_PAGE_PATHS["COUPON_REDEMPTION_IMPORT_HISTORY_DETAILS"] = "/offers/coupons/redemption/import-history/:fileId";
})(APP_PAGE_PATHS = exports.APP_PAGE_PATHS || (exports.APP_PAGE_PATHS = {}));
var APP_BREADCRUMB_LABELS;
(function (APP_BREADCRUMB_LABELS) {
    APP_BREADCRUMB_LABELS["EXCLUSIONS_LIST"] = "EXCLUSIONS LIST";
    APP_BREADCRUMB_LABELS["EXCLUSIONS_CREATE"] = "EXCLUSIONS CREATE";
    APP_BREADCRUMB_LABELS["CUSTOMER_SEGMENTS"] = "CUSTOMER SEGMENTS";
    APP_BREADCRUMB_LABELS["ATTRIBUTES"] = "ATTRIBUTES";
    APP_BREADCRUMB_LABELS["PRICE_LIST"] = "PRICE LISTS";
    APP_BREADCRUMB_LABELS["HOME"] = "HOME";
    APP_BREADCRUMB_LABELS["OFFERS"] = "OFFERS";
    APP_BREADCRUMB_LABELS["COUPON"] = "COUPONS";
    APP_BREADCRUMB_LABELS["HISTORY"] = "HISTORY";
    APP_BREADCRUMB_LABELS["IMPORT"] = "IMPORT";
    APP_BREADCRUMB_LABELS["PRICE"] = "PRICE";
    APP_BREADCRUMB_LABELS["PRODUCT_LIST"] = "PRODUCT LIST";
    APP_BREADCRUMB_LABELS["PRODUCT"] = "PRODUCT";
    APP_BREADCRUMB_LABELS["SETTINGS"] = "SETTINGS";
    APP_BREADCRUMB_LABELS["PROMOTIONS"] = "PROMOTIONS";
    APP_BREADCRUMB_LABELS["IMPORT_HISTORY"] = "IMPORT HISTORY";
    APP_BREADCRUMB_LABELS["EDIT"] = "EDIT";
    APP_BREADCRUMB_LABELS["VARIANT"] = "VARIANT";
    APP_BREADCRUMB_LABELS["REDEMPTION"] = "REDEMPTION";
    APP_BREADCRUMB_LABELS["EXPORT_HISTORY"] = "EXPORT HISTORY";
    APP_BREADCRUMB_LABELS["ALL_COUPONS"] = "ALL COUPONS";
    APP_BREADCRUMB_LABELS["COUPON_IMPORT_HISTORY"] = "IMPORT HISTORY";
    APP_BREADCRUMB_LABELS["COUPON_REDEMPTION_IMPORT_HISTORY"] = "IMPORT HISTORY";
})(APP_BREADCRUMB_LABELS = exports.APP_BREADCRUMB_LABELS || (exports.APP_BREADCRUMB_LABELS = {}));
var CTA_VARIANTS;
(function (CTA_VARIANTS) {
    CTA_VARIANTS["PRIMARY"] = "primary";
    CTA_VARIANTS["SECONDARY"] = "secondary";
    CTA_VARIANTS["DESTRUCTIVE"] = "destructive";
    CTA_VARIANTS["TERTIARY"] = "tertiary";
})(CTA_VARIANTS = exports.CTA_VARIANTS || (exports.CTA_VARIANTS = {}));
exports.DATEPICKER_FORMAT = 'MM/dd/yyyy';
