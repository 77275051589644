import API, { API_URL, proxyDomainInstances } from '../index'
import { FEATURE_FLAGS } from 'src/ds4/config/constants'
import { isFeatureActive } from 'src/ds4/api/featureFlag'
import {
  prepareProductSearchRequest,
  prepareProductSearchRequestWithFilters,
} from 'src/ds4/features/promo/form/components/products-selection-v2/helper'

export const getGroupTree = (type) => {
  const catalogConnector = isFeatureActive({
    flagName: FEATURE_FLAGS.MIGRATE_CATALOG_CONNECTOR,
  })
  return catalogConnector
    ? API.catalogConnector.get(API_URL.GROUP_TREE(type))
    : API.offersConnector.get(API_URL.GROUP_TREE(type))
}

export const getItems = (requestParams, type) => {
  const catalogConnector = isFeatureActive({
    flagName: FEATURE_FLAGS.MIGRATE_CATALOG_CONNECTOR,
  })
  const payload = prepareProductSearchRequest({ request: requestParams, type })
  return catalogConnector
    ? API.catalogConnector.post(API_URL.PRODUCT_SEARCH, payload)
    : API.offersConnector.post(API_URL.PRODUCT_SEARCH, payload)
}

export const getSkuItems = (requestParams, type) => {
  const catalogConnector = isFeatureActive({
    flagName: FEATURE_FLAGS.MIGRATE_CATALOG_CONNECTOR,
  })
  const payload = prepareProductSearchRequestWithFilters({
    request: requestParams,
    type,
  })
  return catalogConnector
    ? API.catalogConnector.post(API_URL.PRODUCT_SEARCH, payload)
    : API.offersConnector.post(API_URL.PRODUCT_SEARCH, payload)
}

export const getItemIdsBySku = (sku) => {
  const catalogConnector = isFeatureActive({
    flagName: FEATURE_FLAGS.MIGRATE_CATALOG_CONNECTOR,
  })
  return catalogConnector
    ? API.catalogConnector.post(API_URL.ITEM_IDS_BY_SKU(), sku)
    : API.offersConnector.post(API_URL.ITEM_IDS_BY_SKU(), sku)
}

export const getAttributesFromPimConnector = ({ limit, offset }) => {
  const catalogConnector = isFeatureActive({
    flagName: FEATURE_FLAGS.MIGRATE_CATALOG_CONNECTOR,
  })
  return catalogConnector
    ? API.catalogConnector.post(API_URL.ATTRIBUTE_CONNECTOR, {
        limit: limit,
        offset: offset,
      })
    : API.offersConnector.post(API_URL.ATTRIBUTE_CONNECTOR, {
        limit: limit,
        offset: offset,
      })
}

export const getAttributesUniqueNames = () =>
  API.catalogConnector.get(API_URL.GET_ATTRIBUTES_NAMES)

export const getAttributeValuesByName = ({ attrName, searchString }) =>
  API.catalogConnector.get(
    API_URL.GET_ATTRIBUTES_VALUES({ attrName, searchString }),
  )

export const searchedAttributesFromPimConnector = ({ body }) => {
  const catalogConnector = isFeatureActive({
    flagName: FEATURE_FLAGS.MIGRATE_CATALOG_CONNECTOR,
  })
  return catalogConnector
    ? API.catalogConnector.post(API_URL.PRODUCT_SEARCH, body)
    : API.offersConnector.post(API_URL.PRODUCT_SEARCH, body)
}

export const getAvailableShippingTypeOptions = () => {
  return proxyDomainInstances.omsV2.then((omsClient) => {
    return omsClient.post(
      API_URL.GET_AVAILABLE_SHIPPING_TYPES(),
      { filters: {}, limit: 0 },
    )
  })
}

export const getLocalesList = () => {
  return API.locale.get(API_URL.GET_LOCALES_LIST)
}
